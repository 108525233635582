import { Injectable } from '@angular/core';

@Injectable()
export class Utils {

    constructor() { }
    
	public lowerCaseProps(obj: any) {
		const parsedObj: any = {};
		Object.keys(obj).forEach((k) => {
			parsedObj[k.toLowerCase()] = obj[k];
		});

		return parsedObj;
	}

	public format(text: string, args: string[]){
		return text.replace(/{(\d+)}/g, (match, number) => { 
			return typeof args[number] != 'undefined'
			  ? args[number]
			  : match
			;
		  });
	}

	public getUrlParameterFromQueryString(parameterName: string, queryString: string): string {
		parameterName = parameterName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
		const regex = new RegExp('[\\?&]' + parameterName + '=([^&#]*)', 'i');
		const results = regex.exec(queryString);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	}
}

