import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { AppToolbarComponent } from './components/toolbar/toolbar.component';
import { IconComponent } from './components/icon/icon.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Utils } from './utils.service';
import { IconService } from './material/icon.service';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { SpinnerComponent } from './components/spinner/spinner.component';

@NgModule({
  declarations: [
    AppToolbarComponent,
    IconComponent,
    SpinnerComponent
  ],
  imports: [
    CommonModule,
		MaterialModule,
		FormsModule,
    ReactiveFormsModule,
    Ng2TelInputModule
  ],
  exports:[
    MaterialModule,
		FormsModule,
    ReactiveFormsModule,
    AppToolbarComponent,
    IconComponent,
    Ng2TelInputModule,
    SpinnerComponent
  ],
  providers: [ Utils, IconService ]
})
export class SharedModule { }
