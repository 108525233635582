import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable()
export class SessionStorageGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const expectedItems = next.data.expectedItems as string[];
    let keysExist = false;
    expectedItems.forEach(x => {
       const val = sessionStorage.getItem(x) ?? '';
       keysExist = val !== '';
    });
    
    if(!keysExist) {
        this.router.navigate(['/message']);
        return false;
    }
    return true;
  }
}
