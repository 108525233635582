import { Component } from '@angular/core';
import { SpinnerService } from '@services/spinner/spinner.service';

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
	active = false;

	constructor(private spinner: SpinnerService) {
		this.spinner.onActiveChanged.subscribe((isActive: boolean) => {
			this.active = isActive;
		});
	}
}
