import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import { Invitation } from '@models/invitations.model';
import { IteroUserMetaData } from '@models/itero-user.model';
import { ActivateService } from '@services/activate/activate.service';
import { InvitationsService } from '@services/invitations/invitations.service';
import { LoggerService } from '@services/logging/logger.service';
import { MessageHandlerService } from '@services/message-handler/message-handler.service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IteroUserGuard implements CanActivate {
  moduleName = 'IteroUserGuard';

  constructor(
    private invitationsService: InvitationsService,
    private activateService: ActivateService,
    private router: Router,
    private logger: LoggerService,
    private messageHandlerService: MessageHandlerService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {

    return this.invitationsService.invitation.pipe(
      switchMap((invitation: Invitation) => this.activateService.checkIteroUser(invitation.companyId).pipe(
        map(iteroUserMetadata => ({
          iteroUserMetadata,
          invitation
        })),
        catchError( err => {
          this.messageHandlerService.handleServerError(err, this.moduleName, 'Activate.checkIteroUser')
            return of(err);
          })
        )),
      map(res => res),
      take(1),
      catchError((err) => {
        const errorMessage = err?.message ?? err;
        this.logger.error(
          `Failed to check if iTeroUser. Error: ${errorMessage}`,
          { module: this.moduleName }
        );
        throw err;
      }), 
      map(
        ({ invitation, iteroUserMetadata }: { invitation: Invitation, iteroUserMetadata: IteroUserMetaData}) => {
          const { isLab: isLabinvitee, isDoctor: isDoctorInvitee, companyId } = invitation;
          const { isIteroUser, hasRelation } = iteroUserMetadata;

          switch (isIteroUser) {
            case true:
              const canActivateIteroUser = this.checkActivateIteroUser({
                isLabinvitee,
                isDoctorInvitee,
                isUserBelongToInvitingCompany: hasRelation,
                companyId
              });
              return (
                canActivateIteroUser.canActivate ||
                this.router.parseUrl(canActivateIteroUser.routerPath)
              );
            case false:
              const canActivateNonIteroUser = this.checkActivateNonIteroUser({
                isLabinvitee,
                isDoctorInvitee,
              });
              return (
                canActivateNonIteroUser.canActivate ||
                this.router.parseUrl(canActivateNonIteroUser.routerPath)
              );
            default:
              return false;
          }
        }
      )
    );
  }

  private checkActivateIteroUser({
    isLabinvitee,
    isDoctorInvitee,
    isUserBelongToInvitingCompany,
    companyId
  }: {
    isLabinvitee: boolean;
    isDoctorInvitee: boolean;
    isUserBelongToInvitingCompany: boolean;
    companyId: number
  }): { canActivate: boolean; routerPath: string } {
    if (isLabinvitee) {
      this.logger.error(
        `User is an existing iTero user. Lab user can only belong to one company. Redirecting to /message -> lab-user page.`,
        { module: this.moduleName }
      );
      return { canActivate: false, routerPath: '/message?context=lab-user' };
    }

    if (isDoctorInvitee && isUserBelongToInvitingCompany) {
      this.logger.info(
        `User belongs to inviting company. Can navigate to /error/belongs-to-inviting-company-error page`,
        { module: this.moduleName }
      );
      return {
        canActivate: false,
        routerPath: '/error/belongs-to-inviting-company-error',
      };
    }

    if (isDoctorInvitee) {
      this.logger.info(`User is iTero user. Redirecting to /otp page.`, {
        module: this.moduleName,
      });
      return { canActivate: false, routerPath: `/otp?company=${companyId}` };
    }

    return { canActivate: false, routerPath: '' };
  }

  private checkActivateNonIteroUser({
    isLabinvitee,
    isDoctorInvitee,
  }: {
    isLabinvitee: boolean;
    isDoctorInvitee: boolean;
  }): { canActivate: boolean; routerPath: string } {
    if (isLabinvitee) {
      this.logger.info(`Lab user. Redirecting to /otp page.`, {
        module: this.moduleName,
      });
      return { canActivate: false, routerPath: '/otp' };
    }

    if (isDoctorInvitee) {
      this.logger.info(
        `User is not Itero User. Can navigate to /activate page`,
        { module: this.moduleName }
      );
      return { canActivate: true, routerPath: '' };
    }

    return { canActivate: false, routerPath: '' };
  }
}
