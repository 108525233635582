import { Injectable } from '@angular/core';
import { ApiRoutes } from '@core/api-routes/api-routes.service';
import { HttpHandlerService } from '@core/services/http-handler/http-handler.service';
import { IteroUserMetaData } from '@models/itero-user.model';
import { IdsAuthenticationResult } from '@models/ids-authentication-result';
import { SignUpRequest } from '@models/signup.model';
import { Observable } from 'rxjs';
import { constants } from '@shared/constants';
import { GenericResponseData } from '@models/generic-response-data.model';
import { GenericResponse } from '@models/generic-response.model';

@Injectable({
  providedIn: 'root'
})
export class ActivateService {
  private _idsFlow = false;
  private _email: string = '';

  set idsFlow(idsFlow: boolean) {
    this._idsFlow = idsFlow;
  }

  set email(email: string) {
    this._email = email;
  }
  
  get email(): string {
    return this._email;
  }

  get metadataToken(): string {
    return sessionStorage.getItem(constants.metadataToken) ?? '';
  }

  constructor(private httpHandler: HttpHandlerService) { }

  signup(request: SignUpRequest): Observable<boolean>{
    if (this._idsFlow)
      return this.httpHandler.post<boolean>(ApiRoutes.Activate.signupwithids(), request);
    else
      return this.httpHandler.post<boolean>(ApiRoutes.Activate.signup(), request);
  }

  getSecurityQuestionsTags(): Observable<string[]>{
    return this.httpHandler.get<string[]>(ApiRoutes.Activate.securityQuestionsTags());
  }

  checkIteroUser(companyId: number): Observable<IteroUserMetaData> {
    return this.httpHandler.get<IteroUserMetaData>(
      ApiRoutes.Activate.checkIteroUser(this.metadataToken, companyId)
    );
  }

  createRelationBaseOnItero(): Observable<GenericResponse>{
    return this.httpHandler.post<GenericResponse>(ApiRoutes.Activate.createRelationBaseOnItero());
  }

  createRelationBaseOnIds(): Observable<GenericResponse>{
    return this.httpHandler.post<GenericResponse>(ApiRoutes.Activate.createRelationBaseOnIds());
  }

  getIdsLoginUrl(langCode: string): Observable<string>{
    return this.httpHandler.get<string>(ApiRoutes.Activate.idsLoginUrl(this.metadataToken, langCode));
  }

  getIdsAuthenticationResultUrl(): Observable<GenericResponseData<IdsAuthenticationResult>>{
    return this.httpHandler.get<GenericResponseData<IdsAuthenticationResult>>(ApiRoutes.Activate.idsAuthenticationResult(this.metadataToken));
  }

  removeIdsFlow(): Observable<void> {
    return this.httpHandler.post(ApiRoutes.Activate.removeIdsFlow(this.metadataToken));
  }

  allowInvisalignWithNewItero(): Observable<boolean>{
    return this.httpHandler.get<boolean>(ApiRoutes.Activate.allowInvisalignWithNewItero());
  }
}
