import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationExtras, UrlTree } from '@angular/router';
import { LanguageService } from '@core/services/language/language.service';
import { InvitationValidation } from '@models/invitations.model';
import { InvitationsService } from '@services/invitations/invitations.service';
import { LoggerService } from '@services/logging/logger.service';
import { MessageHandlerService } from '@services/message-handler/message-handler.service';
import { constants } from '@shared/constants';
import { RowStatus } from '@shared/enums';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ValidGuard implements CanActivate {

  private readonly moduleName = 'ValidGuard';

  constructor(
      private invitationService: InvitationsService,
      private router: Router,
      private languageService: LanguageService,
      private logger: LoggerService,
      private messageHandlerService: MessageHandlerService
  ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        
            const metadataToken = encodeURIComponent(next.params.metadataToken);
            if(!metadataToken){
              return of(false);
            }
            sessionStorage.setItem(constants.metadataToken, metadataToken);
            return this.invitationService.validate(metadataToken).pipe(
              map((res: InvitationValidation) => {
                const { langCode, doctorName, clinicName } = res;
                this.languageService.setLanguage(langCode);
                const shouldNavigateToExpiredPage = res && res.rowStatus === RowStatus.InActive || res.rowStatus === RowStatus.Deleted;
                const shouldNavigateToAcceptedPage = res && 
                    (res.rowStatus === RowStatus.Resolved || 
                    res.rowStatus === RowStatus.PendingLogin || 
                    res.rowStatus === RowStatus.PendingRelation);

                if (shouldNavigateToExpiredPage || shouldNavigateToAcceptedPage) {
                  this.logger.warn(`Navigating to /message`,  { module: this.moduleName });
                  sessionStorage.clear();
                  return this.router.createUrlTree(['/message']);
                }

                const isInvitationActive = res?.rowStatus === RowStatus.Active;
                this.logger.debug(`invitation is${isInvitationActive ? '' : 'not'} valid`,  { module: this.moduleName });

                if (!isInvitationActive) {
                  sessionStorage.clear();
                }
                return isInvitationActive;
              }),
              catchError( err => {
                this.messageHandlerService.handleServerError(err, this.moduleName, 'Invitations.validate');
                sessionStorage.clear();
                return of(err);
              })
            );
      }
}