export const constants = {
  metadataToken: 'metadata',
  jwtKeyName: 'token',
  baseUrl: 'baseurl'
} as const;

export const messageContext = {
  labUser: 'lab-user',
  error: 'error',
  invisalignWithNewItero: 'invisalign-with-new-itero',
  idsFlowPhoneDoesNotMatch: 'ids-flow-phone-does-not-match',
  IdsFlowActiveInvitationConflict: 'ids-flow-active-invitation-conflict'
}
