import { Injectable } from '@angular/core';
import { ApiRoutes } from '@core/api-routes/api-routes.service';
import { HttpHandlerService } from '@core/services/http-handler/http-handler.service';
import { Invitation, InvitationValidation } from '@models/invitations.model';
import { constants } from '@shared/constants';
import { RowStatus } from '@shared/enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InvitationsService {
  private _invitationSubject = new BehaviorSubject<any>(null);
  private _invitation$: Observable<Invitation> = this._invitationSubject.asObservable();

  constructor(private httpHandler: HttpHandlerService) {}

  get metadataToken(): string {
    return sessionStorage.getItem(constants.metadataToken) ?? '';
  }

  get invitation(): Observable<Invitation> {
    if (!this._invitationSubject.getValue()) {
      this._invitation$ = this.getInvitation().pipe(tap((inv) => this._invitationSubject.next(inv)),shareReplay(1) );
    }
    else {
      this._invitation$ = this._invitationSubject.asObservable();
    }
    return this._invitation$;
  }

  getInvitation(): Observable<Invitation> {
    return this.httpHandler
      .get<Invitation>(ApiRoutes.Invitations.get(this.metadataToken))
      .pipe(tap((res) => res));
  }

  validate(metadataToken: string): Observable<InvitationValidation> {
    return this.httpHandler.get<InvitationValidation>(
      ApiRoutes.Invitations.validate(metadataToken)
    );
  }

  setStatus(status: RowStatus): void {
    this._invitationSubject.next({...this._invitationSubject.getValue(), rowStatus: status});
  }
}
