import { Injectable, OnDestroy } from '@angular/core';
import { Timber } from '@itero/timber';
import { IRemoteConfig } from '@itero/timber/interfaces/config.remote';
import { ILogParameters } from '@itero/timber/interfaces/logParameters';
import { Subject } from 'rxjs';
import { loggerConfigRemote } from './config/logger.config';
import { AppSettingsService } from '@core/services/app-settings/app-settings.service';
import { LogParameters } from './interfaces/log-parameters';

@Injectable({ providedIn: 'root' })
export class LoggerService implements OnDestroy {

	private timber!: Timber;
	private onDestroy$ = new Subject<boolean>();

	constructor(private appSettingsService: AppSettingsService) {
		this.createLogger();
	}

	trace(message: string, parameters: LogParameters): void { this.timber.trace(message, parameters as ILogParameters); }

	debug(message: string, parameters: LogParameters): void { this.timber.debug(message, parameters as ILogParameters); }

	info(message: string, parameters: LogParameters): void { this.timber.info(message, parameters  as ILogParameters); }

	warn(message: string, parameters: LogParameters): void { this.timber.warn(message, parameters  as ILogParameters); }

	error(message: string, parameters: LogParameters): void { this.timber.error(message, parameters as ILogParameters); }

	fatal(message: string, parameters: LogParameters): void { this.timber.fatal(message, parameters as ILogParameters); }

	ngOnDestroy() {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}

	private createLogger(): void {
		const config: IRemoteConfig = {
			...loggerConfigRemote as IRemoteConfig,
			url: this.appSettingsService.appSettings.loggingEndpoint
		};
		this.timber = new Timber(config);
		console.log('Logger created with config:', config);
	}
}
