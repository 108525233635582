import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'activate',
        loadChildren: () => import('./pages/activate-account/activate-account.module').then(m => m.ActivateModule)
      },
      {
        path: 'otp',
        loadChildren: () => import('./pages/otp-verification/otp-verification.module').then(m => m.OtpVerificationModule)
      },
      {
        path: 'signup',
        loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpModule)
      },
      {
        path: 'message',
        loadChildren: () => import('./pages/message/message.module').then(m => m.MessageModule)
      },
      {
        path: 'error',
        loadChildren: () => import('./pages/errors/global-error.component.moudle').then(m => m.GlobalErrorModule)
      },
      {
        path: 'idsauthresponse',
        loadChildren: ()=> import('./shared/components/ids/idsAuthResponse/idsAuthResponse.module').then(m => m.IdsAuthResponseModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
