import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggerService } from '@services/logging/logger.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(private injector: Injector) {

  }

  handleError(error: Error) {
    const timberMsg = `${error.message ? error.message : error}`;

    this.injector.get(LoggerService).error(timberMsg, { module: 'ErrorHandler' });

    console.error(error);
  }
}
