export enum LanguageCode {
	German = 'de-DE',
	English = 'en-US',
	Spanish = 'es-ES',
	French = 'fr-FR',
	Italian = 'it-IT',
	Japanese = 'ja-JP',
	Korean = 'ko-KR',
	Portuguese = 'pt-BR',
	Russian = 'ru-RU',
	Thai = 'th-TH',
	Turkish = 'tr-TR',
	ChineseS = 'zh-CN',
	ChineseT = 'zh-TW'
}

export enum RowStatus {
	Active = 1,
	PendingLogin = 2,
    PendingRelation = 3,
	Resolved = 4,
	Deleted = 5,
	InActive = 6
}

export enum HttpStatusCode {
	Ok = 200,
	BadRequest = 400,
	Unauthorized = 401,
	Forbidden = 403,
	Conflict = 409
}

export enum UserType {
	Dentist = 19,
	Orthodontist = 20,
	Operational_Office_Personnel = 26,
	Clinical_Office_Personnal = 27,
	ExtStaff_Lab = 9
}

export const UserTypeLabel = new Map<number, string>([
	[UserType.Dentist, 'Dentist'],
	[UserType.Orthodontist, 'Orthodontist'],
	[UserType.Operational_Office_Personnel, 'Operational Office Personnel'],
	[UserType.Clinical_Office_Personnal, 'Clinical Office Personnal']
]);

export enum Icons {
	success,
	error,
	info,
	noIcon
}