export class ApiRoutes {

  public static Invitations = class {
    private static domain = 'v1/invitations';

    static validate = (metadataToken: string) => `${ApiRoutes.Invitations.domain}/validate?metadataToken=${metadataToken}`;
    static get = (metadataToken: string) => `${ApiRoutes.Invitations.domain}?metadataToken=${metadataToken}`;
    static send = () => `${ApiRoutes.Invitations.domain}`;
  }

  public static Activate = class {
    private static domain = '/activate';

    static signup = () => `v1${ApiRoutes.Activate.domain}/sign-up`;
    static signupwithids = () => `v1${ApiRoutes.Activate.domain}/sign-up-with-ids`;
    static checkIteroUser = (metadataToken: string, companyId: number) => `v2${ApiRoutes.Activate.domain}/itero-user?metadataToken=${metadataToken}&companyId=${companyId}`;
    static createRelationBaseOnItero = () => `v1${ApiRoutes.Activate.domain}/create-relation-itero`;
    static createRelationBaseOnIds = () => `v1${ApiRoutes.Activate.domain}/create-relation-ids`;
    static securityQuestionsTags = () => `v1${ApiRoutes.Activate.domain}/security-questions-tags`;
    static idsLoginUrl = (metadataToken: string, langCode: string) => `v1${ApiRoutes.Activate.domain}/ids-login-url?metadataToken=${metadataToken}&langCode=${langCode}&successEndpoint=${window.location.origin}/idsauthresponse?isSuccess=true`;
    static idsAuthenticationResult = (metadataToken: string) => `v1${ApiRoutes.Activate.domain}/ids-authentication-result?metadataToken=${metadataToken}`;
    static getIteroEmail = (contactId: string) => `v1${ApiRoutes.Activate.domain}/itero-email/${contactId}`;
    static removeIdsFlow = (metadataToken: string) => `v1${ApiRoutes.Activate.domain}/remove-ids-flow?metadataToken=${metadataToken}`;
    static allowInvisalignWithNewItero = () => `v1${ApiRoutes.Activate.domain}/allow-invisalign-with-new-itero`;
  }

  public static InvitationsOtp = class {
    private static domain = 'v1/invitations-otp';
    static sendOtpSms = (metadataToken :string, langCode: string) => `${ApiRoutes.InvitationsOtp.domain}/send?metadataToken=${metadataToken}&langCode=${langCode}`;
    static validateOtp = (code: string, metadataToken :string) => `${ApiRoutes.InvitationsOtp.domain}/validate?code=${code}&metadataToken=${metadataToken}`;
  }
}
