import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettingsService } from '@core/services/app-settings/app-settings.service';
import { constants } from '@shared/constants';

@Injectable({
	providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {

	constructor() {
	}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const token = sessionStorage.getItem(constants.jwtKeyName);

		if (token) {
			request = request.clone({
				headers: request.headers.set('Authorization', `Bearer ${token}`),
			});
		}

		if (!request.headers.has('Content-Type')) {
			request = request.clone({
				headers: request.headers.set('Content-Type', 'application/json'),
			});
		}

		request = request.clone({
			headers: request.headers.set('Accept', 'application/json'),
		});

		return next.handle(request);
	}
}
