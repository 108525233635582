import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from '@services/logging/logger.service';
import { messageContext } from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class MessageHandlerService {
  constructor(private logger: LoggerService, private router: Router) {}
  context: string = '';
  moduleName: string = 'MessageHandlerService';

  handleServerError(error: any, origin: string, method: string): void {
    const errorMessage = error?.message ?? error;
    this.logger.error(`Server error! ${errorMessage} occured in method: ${method}`, {module: this.moduleName});
    this.logger.debug(`Navigating from /${origin} to /message page with context: error`, { module: this.moduleName });
    this.router.navigate(['/message'], { queryParams: { context: messageContext.error } });
  }
}
