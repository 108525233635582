import { Injectable } from '@angular/core';
import { Language } from '@models/language.model';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@services/logging/logger.service';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppSettingsService } from '../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private languageChangedSource = new BehaviorSubject<Language>(this.appSettingsService.appSettings.defaultLanguage);
  languageChanged$ = this.languageChangedSource.asObservable().pipe(tap(language => {
    this.logger.info(`Language changed to ${language.code} | ${language.name}`, { module: 'LanguageService' });
  }));

  constructor(
    private appSettingsService: AppSettingsService,
    private translateService: TranslateService,
    private logger: LoggerService
  ) { }

  setLanguage(languageCode: string): void {
    const language = this.appSettingsService.appLocaleData.languages.filter(lang => lang.code === languageCode)[0];
    this.translateService.use(language.code).subscribe(() => {
      this.languageChangedSource.next(language);
    });
  }
}
