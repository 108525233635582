import { Component, OnInit, Input } from '@angular/core';
import { IconService } from '@shared/material/icon.service';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  @Input()
  public iconName: string = '';

  constructor(private iconService: IconService) { }

  ngOnInit(): void {
    if (this.iconName !== '')
      this.iconService.registerIcon(this.iconName);
  }
}
